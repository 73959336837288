import React, { useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { checkAuth } from "./redux/slices/authSlices/authSlice";
import MainLayout from "./layouts/MainLayout";
import MinimalLayout from "./layouts/MinimalLayout";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Register from "./pages/auth/Register";
import Login from "./pages/auth/Login";
import ForgotPassword from "./pages/auth/ForgotPassword";
import CreateAd from "./pages/ads/CreateAd";
import AdDetails from "./pages/ads/AdDetails";
import UserAds from "./pages/ads/UserAds";
import Dashboard from "./pages/Dashboard";
import UserProfile from "./pages/users/UserProfile";
import UserOfficialInfo from "./pages/users/UserOfficialInfo";
import RegistrationSuccess from "./pages/auth/RegistrationSuccess";
import NetworkingList from "./pages/Networking/NetworkingList";
import NetworkFriend from "./pages/Networking/NetworkFriend";
import Chat from "./pages/Messenger/Chat";
import Formation from "./pages/Formations/Formation";  
import Evenement from "./pages/Evenement";
import Historique from "./pages/Historique";
import AdsPages from "./pages/AdsPage";
import "./index.css";
import Actualite from "./pages/Actualite";
import AlertEmail from "./pages/users/AlerteEmail";
import UserSelection from "./pages/users/UserSelection";
import UserTemoignage from "./pages/users/UserTemoignage";
import UserChangeEmail from "./pages/users/UserChangeEmail";
import UserUnsubscribe from "./pages/users/UserUnsubscribe";
import About from "./pages/About";
import ChatComponent from "./components/ChatComponent";

const App = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isAuthenticated, token, isLoading } = useSelector((state) => state.auth);

  // ✅ Vérifie l'authentification au chargement si un token est présent
  useEffect(() => {
    console.log("🔑 Vérification du token dans sessionStorage:", sessionStorage.getItem("token"));

    if (sessionStorage.getItem("token")) {
      dispatch(checkAuth());
    }
  }, [dispatch]);

  // ✅ Liste des routes utilisant MinimalLayout
  const minimalLayoutRoutes = [
    "/register",
    "/login",
    "/forgot-password",
    "/register/success",
  ];

  // ✅ Spinner de chargement pendant l'authentification
  // if (isLoading) {
  //   return (
  //     <div className="d-flex justify-content-center align-items-center vh-100">
  //       <div className="spinner-border" role="status">
  //         <span className="sr-only">Chargement...</span>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <>
      {minimalLayoutRoutes.includes(location.pathname) ? (
        <MinimalLayout>
          <Routes>
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/register/success" element={<RegistrationSuccess />} />
          </Routes>
        </MinimalLayout>
      ) : (
        <MainLayout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/networking" element={<NetworkingList />} />
            <Route path="/networking/friend" element={<NetworkFriend />} />
            <Route path="/messenger" element={<Chat />} />
            <Route path="/learning" element={<Formation />} />
            <Route path="/about" element={<About />} />
            <Route path="/event" element={<Evenement />} />
            <Route path="/historique" element={<Historique />} />
            <Route path="/pages" element={<AdsPages />} />
            <Route path="/actualites" element={<Actualite />} />
            <Route path="/ads/create" element={isAuthenticated ? <CreateAd /> : <Navigate to="/login" />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/ads/:slug" element={<AdDetails />} />

            {/* ✅ Protège les routes nécessitant une authentification */}
            <Route path="/my-ads" element={isAuthenticated ? <UserAds /> : <Navigate to="/login" />} />
            <Route path="/dashboard" element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" />} />
            <Route path="/alert/email" element={isAuthenticated ? <AlertEmail /> : <Navigate to="/login" />} />
            <Route path="/user/selection" element={isAuthenticated ? <UserSelection /> : <Navigate to="/login" />} />
            <Route path="/user/testimonials" element={isAuthenticated ? <UserTemoignage /> : <Navigate to="/login" />} />
            <Route path="/user/change/email" element={isAuthenticated ? <UserChangeEmail /> : <Navigate to="/login" />} />
            <Route path="/user/unsubscribe" element={isAuthenticated ? <UserUnsubscribe /> : <Navigate to="/login" />} />
            <Route path="/user/chat/:conversationId" element={isAuthenticated ? <ChatComponent /> : <Navigate to="/login" />} />
            <Route path="/user/profile" element={isAuthenticated ? <UserProfile /> : <Navigate to="/login" />} />
            <Route path="/user/official/infos" element={isAuthenticated ? <UserOfficialInfo /> : <Navigate to="/login" />} />
          </Routes>
        </MainLayout>
      )}
    </>
  );
};

export default App;
