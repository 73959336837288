// src/redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlices/authSlice';
import userAdsReducer from './slices/adSlices/userAdsSlice';
import adDetailsReducer from "./slices/adSlices/adDetailsSlice";
import adsReducer from './slices/adSlices/AllAdsSlice';
import createAdReducer from './slices/adSlices/createAdSlice';
import userReducer from "./slices/authSlices/userSlice";
import userProfileReducer from "./slices/userSlices/userProfileSlice";
import userOfficialInfoReducer from "./slices/userSlices/userOfficialInfoSlice";
import userMessageReducer from "./slices/chatSlices/userMessage";
import userContactsReducer from "./slices/chatSlices/userContacts";
import userListReducer from "./slices/userSlices/UserList";
import conversationReducer from "./slices/chatSlices/conversationSlice";



export const store = configureStore({
  reducer: {
    auth: authReducer,
    ads: adsReducer, 
    createAd: createAdReducer, 
    userAds: userAdsReducer,
    adDetails: adDetailsReducer,
    users: userReducer,
    userProfile: userProfileReducer,
    userOfficialInfo: userOfficialInfoReducer,
    userMessage: userMessageReducer,
    userContacts: userContactsReducer,
    userList: userListReducer,
    conversation: conversationReducer,

  },
});

