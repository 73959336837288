import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../utils/api"; // 
import { API_ROUTES } from "../../../utils/Constants";

// ✅ Récupérer les 10 derniers utilisateurs
export const fetchRecentUsers = createAsyncThunk(
  "users/fetchRecentUsers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(API_ROUTES.RECENT_USERS);

      console.log("Réponse API (Recent Users):", response.data); // ✅ Debugging

      return response.data.map((user) => ({
        firstname: user.firstname,
        country: user.country || "Pays inconnu",
        created_at: user.created_at,
      }));
    } catch (error) {
      console.error("Erreur API (Recent Users):", error.response);
      return rejectWithValue(error.response?.data || "Erreur de serveur");
    }
  }
);

const userSlice = createSlice({
  name: "users",
  initialState: {
    recentUsers: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRecentUsers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchRecentUsers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.recentUsers = action.payload;
      })
      .addCase(fetchRecentUsers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default userSlice.reducer;
