import React, { useState } from "react";
import SearchBar from "../components/SearchBar";
import Filters from "../components/Filters";
import Pagination from "../components/Pagination";
import Loader from "../components/Loader";
import ListAd from "./ads/ListAd";
import DisplayToggle from "../components/UI/DisplayToggle";

const ListAdsHome = ({
  searchTerm,
  setSearchTerm,
  selectedDateRange,
  setSelectedDateRange,
  selectedDomain,
  setSelectedDomain,
  filteredAds,
  loading,
  error,
  resetFilters,
  currentPage,
  totalPages,
  handlePageChange,
}) => {
  const [viewMode, setViewMode] = useState("list"); // "list" ou "card"

  return (
    <div>
      <div
        className="content p-3"
        style={{
          backgroundColor: "#fff",
          borderRadius: "15px",
          border: "1px solid #ddd",
          fontFamily: "Nunito",
          fontWeight: "500",
          fontStyle: "normal",
        }}
      >
        <div className="row gy-3 align-items-center">
          {/* Recherche */}
          <div className="col-12 col-lg-4">
            <SearchBar
              searchTerm={searchTerm}
              handleSearchChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          {/* Filtres */}
          <div className="col-12 col-lg-5">
            <Filters
              selectedDateRange={selectedDateRange}
              handleDateRangeChange={(e) => setSelectedDateRange(e.target.value)}
              selectedDomain={selectedDomain}
              handleDomainChange={(e) => setSelectedDomain(e.target.value)}
            />
          </div>

          {/* Bouton de basculement */}
          <div className="col-12 col-md-12 col-sm-12 col-lg-3 d-flex justify-content-center justify-content-lg-end">
              <DisplayToggle viewMode={viewMode} setViewMode={setViewMode} />
          </div>
        </div>
      </div>

      {/* Liste des annonces */}
      <div className="mt-4">
        {loading ? (
          <Loader />
        ) : error ? (
          <p>Erreur : {error}</p>
        ) : filteredAds.length > 0 ? (
          filteredAds.map((ad) => (
            <ListAd
              key={ad.id}
              slug={ad.slug}
              imageUrl={(ad.media?.length > 0 && ad.media[0]?.file_path) || "https://via.placeholder.com/150"}
              title={ad.title}
              author={ad.user?.firstname || "Auteur inconnu"}
              need_type={ad.need_type}
              food_industry_field={ad.food_industry_field}
              description={ad.description}
              createdAt={ad.created_at}
              quantity={ad.quantity}
              price={ad.price}
              viewMode={viewMode} 
            />
          ))
        ) : (
          <div className="text-center">
            <p>Aucune annonce correspondant à votre recherche n'a été trouvée.</p>
            <button
              className="btn btn-primary"
              onClick={resetFilters}
              style={{
                borderRadius: "10px",
                fontWeight: "bold",
                backgroundColor: "#036",
                border: "none",
              }}
            >
              Réinitialiser les filtres de recherche
            </button>
          </div>
        )}
      </div>

      {/* Pagination en bas */}
      <div className="row mt-2 mb-3">
        <div className="col-12 d-flex justify-content-end">
          <div
            className="p-2"
            style={{
              backgroundColor: "#fff",
              borderRadius: "15px",
              border: "1px solid #ddd",
            }}
          >
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default ListAdsHome;
