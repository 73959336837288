import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; 
import UserMenuCard from "./users/UserMenuCard"; 

const Actualite = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const navigate = useNavigate(); 

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="container mt-4"
      style={{
        maxWidth: "100%",
        marginLeft: isMobile ? "0px" : "210px",
        fontFamily: "Nunito",
        fontWeight: "500",
        fontStyle: "normal",
        padding: "30px",
      }}
    >
      <div className="row">
        
        {/* Colonne principale (col-9) avec une Card */}
        <div className="col-lg-12 col-12 d-flex justify-content-center align-items-center text-center" style={{ height: "80vh" }}>
          <div className="card p-4 shadow" style={{ width: "100%", maxWidth: "600px" }}> {/* ✅ Ajout d'une Card */}
            <div className="card-body">
              <h3 className="card-title mb-3">Fonctionnalité en développement</h3>
              <p className="card-text text-muted">
                Cette fonctionnalité est en cours de développement.<br />
                Revenez bientôt pour découvrir les nouvelles mises à jour !
              </p>
            </div>
          </div>
        </div>

        {/* Colonne UserMenuCard (col-3) */}
        {/* <div className="col-lg-3 col-12 mb-3">
          <UserMenuCard />
        </div> */}

      </div>
    </div>
  );
};

export default Actualite;
