import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "../../../utils/api"; 

// 🔥 Récupérer les messages d'une conversation
export const fetchMessages = createAsyncThunk(
  "userMessage/fetchMessages",
  async (conversationId, { rejectWithValue }) => {
    try {
      const userToken = sessionStorage.getItem("token");
      if (!userToken) throw new Error("Utilisateur non authentifié");

      const response = await axios.get(
        `${API_BASE_URL}/conversations/${conversationId}/messages`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      return response.data.messages; 
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// ✅ Envoyer un message
export const sendMessage = createAsyncThunk(
  "userMessage/sendMessage",
  async ({ conversationId, message }, { rejectWithValue }) => {
    try {
      const userToken = sessionStorage.getItem("token");
      if (!userToken) throw new Error("Utilisateur non authentifié");

      const response = await axios.post(
        `${API_BASE_URL}/messages`,
        { conversation_id: conversationId, message },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      return response.data.message; 
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const userMessageSlice = createSlice({
  name: "userMessage",
  initialState: {
    messages: [],
    status: "idle",
    error: null,
  },
  reducers: {
    resetMessagesState: (state) => {
      state.messages = [];
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMessages.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchMessages.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.messages = action.payload;
      })
      .addCase(fetchMessages.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        state.messages.push(action.payload);
      });
  },
});

export const { resetMessagesState } = userMessageSlice.actions;
export default userMessageSlice.reducer;
