import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "../../../utils/api";

// ✅ Fonction pour récupérer les headers avec le token
const getAuthHeaders = () => ({
  Authorization: `Bearer ${sessionStorage.getItem("token")}`,
});

// ✅ Thunk pour récupérer toutes les conversations de l'utilisateur connecté
export const fetchConversations = createAsyncThunk(
  "conversation/fetchConversations",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/conversations`, {
        headers: getAuthHeaders(),
      });
      return response.data; // Retourne la liste des conversations
    } catch (error) {
      return rejectWithValue(error.response?.data || "Erreur serveur");
    }
  }
);

// ✅ Thunk pour créer une conversation ou récupérer une existante
export const startConversation = createAsyncThunk(
  "conversation/startConversation",
  async (receiverId, { getState, rejectWithValue }) => {
    try {
      const { conversation } = getState();
      
      // 🔍 Vérifier si la conversation existe déjà
      const existingConversation = conversation.conversations.find(
        (convo) =>
          (convo.sender_id === receiverId || convo.receiver_id === receiverId)
      );

      if (existingConversation) {
        return existingConversation; // Retourner la conversation existante
      }

      // ✅ Créer une nouvelle conversation si elle n'existe pas
      const response = await axios.post(
        `${API_BASE_URL}/conversations`,
        { receiver_id: receiverId },
        { headers: getAuthHeaders() }
      );

      return response.data; // Retourne la nouvelle conversation
    } catch (error) {
      return rejectWithValue(error.response?.data || "Erreur inconnue");
    }
  }
);

const conversationSlice = createSlice({
  name: "conversation",
  initialState: {
    conversations: [], // ✅ Stocke toutes les conversations de l'utilisateur
    currentConversation: null, // ✅ Conversation en cours
    status: "idle",
    error: null,
  },
  reducers: {
    resetConversationState: (state) => {
      state.currentConversation = null;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // ✅ Récupérer toutes les conversations
      .addCase(fetchConversations.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchConversations.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.conversations = action.payload;
      })
      .addCase(fetchConversations.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // ✅ Démarrer une conversation
      .addCase(startConversation.pending, (state) => {
        state.status = "loading";
      })
      .addCase(startConversation.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.currentConversation = action.payload;
        state.conversations.push(action.payload);
      })
      .addCase(startConversation.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetConversationState } = conversationSlice.actions;
export default conversationSlice.reducer;
