import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';

const MainLayout = ({ children }) => {
  const [showSidebar, setShowSidebar] = useState(true); // État pour afficher ou masquer la sidebar

  const toggleSidebar = () => {
    setShowSidebar((prev) => !prev); // Inverse l'état actuel
  };

  return (
    <div className="d-flex flex-column" style={{ minHeight: '100vh' }}>
      {/* Navbar */}
      <Navbar toggleSidebar={toggleSidebar} />

      {/* Sidebar and Content */}
      <div
        className="d-flex flex-grow-1"
        style={{
          backgroundColor: '#f0f2f5',
        }}
      >
        {/* Sidebar contrôlée par l'état */}
        {showSidebar && (
          <div
            className="d-none d-md-block"
            style={{
              width: '0', // Largeur fixe de la sidebar
              flexShrink: 0, // Empêche le redimensionnement
            }}
          >
            <Sidebar />
          </div>
        )}

        {/* Main Content */}
        <main
          className="flex-grow-1 main-content"
          style={{
            padding: '30px',
            transition: 'margin 0.3s ease', // Animation de transition
          }}
        >
          {children}
        </main>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default MainLayout;
