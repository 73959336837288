
// Base URL de l'API
export const API_BASE_URL = "https://api.espacenegoce.com/api";

// export const API_BASE_URL = "http://127.0.0.1:8000/api";

// Routes de l'API
export const API_ROUTES = {
  REGISTER: `${API_BASE_URL}/register`, 
  LOGIN: `${API_BASE_URL}/login`,     
  CREATE_AD: `${API_BASE_URL}/ads`,   
  RECENT_USERS: `${API_BASE_URL}/registered`, 
};

export const AGRO_DOMAINS = [
  { value: "", label: "Sélectionner une rubrique" },
  { value: "Aliments Pour Animaux", label: "Aliments Pour Animaux" },
  { value: "Animaux Vivants", label: "Animaux Vivants" },
  { value: "Bois Charbon Fibre", label: "Bois Charbon Fibre" },
  { value: "Boisson Alcoolisée", label: "Boisson Alcoolisée" },
  { value: "Boissons Non Alcoolisée", label: "Boissons Non Alcoolisée" },
  { value: "Café Cacao Thé", label: "Café Cacao Thé" },
  { value: "Champignon Levure", label: "Champignon Levure" },
  { value: "Chimie Additif Engrais", label: "Chimie Additif Engrais" },
  { value: "Ciment", label: "Ciment" },
  { value: "Coquillage Crustacé", label: "Coquillage Crustacé" },
  { value: "Conditionnement", label: "Conditionnement" },
  { value: "Conseil et Formation", label: "Conseil et Formation" },
  { value: "Conserve Semi Conserve", label: "Conserve Semi Conserve" },
  { value: "Céréales", label: "Céréales" },
  { value: "Emploi Représentation", label: "Emploi Représentation" },
  { value: "Énergie Renouvelable", label: "Énergie Renouvelable" },
  { value: "Épices Aromates", label: "Épices Aromates" },
  { value: "Équipement Maritime", label: "Équipement Maritime" },
  { value: "Féculents", label: "Féculents" },
  { value: "Fruits Secs et Graines Grillées", label: "Fruits Secs et Graines Grillées" },
  { value: "Fruits et Légumes", label: "Fruits et Légumes" },
  { value: "Horticulture Plante Biocarburant", label: "Horticulture Plante Biocarburant" },
  { value: "Huiles", label: "Huiles" },
  { value: "Hygiène Entretien", label: "Hygiène Entretien" },
  { value: "Industrie Exploitation", label: "Industrie Exploitation" },
  { value: "Investissement Capitaux", label: "Investissement Capitaux" },
  { value: "Jus Concentré Sirop", label: "Jus Concentré Sirop" },
  { value: "Lait et Produits Laitiers", label: "Lait et Produits Laitiers" },
  { value: "Matériels Machines", label: "Matériels Machines" },
  { value: "Matière Végétale", label: "Matière Végétale" },
  { value: "Matières Grasses", label: "Matières Grasses" },
  { value: "Métaux", label: "Métaux" },
  { value: "Mine Terrain Concession", label: "Mine Terrain Concession" },
  { value: "Patisserie", label: "Patisserie" },
  { value: "Pierre Roche", label: "Pierre Roche" },
  { value: "Poissons", label: "Poissons" },
  { value: "Produits Sucrés", label: "Produits Sucrés" },
  { value: "Publicité-Nouveaux Produits", label: "Publicité-Nouveaux Produits" },
  { value: "Pétrole Gaz", label: "Pétrole Gaz" },
  { value: "Semence Graine Plant", label: "Semence Graine Plant" },
  { value: "Transport Transit Logistique", label: "Transport Transit Logistique" },
  { value: "Vins", label: "Vins" },
  { value: "Viandes et Oeufs", label: "Viandes et Oeufs" },
  { value: "Autres", label: "Autres" },

];

// Liste des rôles (Acteurs)
export const ACTORS = [
  { value: "", label: "Sélectionner" },
  { value: "Importateur", label: "Importateur" },
  { value: "Exportateur", label: "Exportateur" },
  { value: "Distributeur", label: "Distributeur" },
  { value: "Fabricant", label: "Fabricant" },
  { value: "Producteur", label: "Producteur" },
];

export const COUNTRIES = [
  { code: "FR", name: "France" },
  { code: "BE", name: "Belgique" },
  { code: "DE", name: "Allemagne" },
  { code: "SN", name: "Sénégal" },
  { code: "BJ", name: "Bénin" },
  { code: "CI", name: "Côte d'Ivoire" },
  { code: "MG", name: "Madagascar" },
  { code: "NG", name: "Nigeria" },
  { code: "MA", name: "Maroc" },
  { code: "TN", name: "Tunisie" },
  { code: "EG", name: "Égypte" },
  { code: "GH", name: "Ghana" },
  { code: "CM", name: "Cameroun" },
  { code: "ZA", name: "Afrique du Sud" },
];

export const networkingData = [
  { id: 1, domain: "Energie renouvelable", country: "France" },
  { id: 2, domain: "Agroalimentaire", country: "France" },
  { id: 3, domain: "Matières Premières", country: "Canada" },
  { id: 4, domain: "Agroalimentaire", country: "Bénin" },
  { id: 5, domain: "Agroalimentaire", country: "France" },
  { id: 6, domain: "Matières Premières", country: "Canada" },
  { id: 7, domain: "Agroalimentaire", country: "Bénin" },
  { id: 8, domain: "Matières Premières", country: "Canada" },
  { id: 9, domain: "Matières Premières", country: "Canada" },
  { id: 10, domain: "Commerce", country: "États-Unis" },
  { id: 11, domain: "Textile", country: "Inde" },
  { id: 12, domain: "Agroalimentaire", country: "Espagne" },
  { id: 13, domain: "Pharmaceutique", country: "Allemagne" },
  { id: 14, domain: "Matières Premières", country: "Brésil" },
  { id: 15, domain: "Technologie", country: "Chine" },
  { id: 16, domain: "Transport", country: "Royaume-Uni" },
  { id: 17, domain: "Logistique", country: "Afrique du Sud" },
  { id: 18, domain: "Construction", country: "Nigéria" },
  { id: 19, domain: "Agroalimentaire", country: "Italie" },
  { id: 21, domain: "Industrie", country: "Belgique" },
  { id: 22, domain: "Finance", country: "Suisse" },
  { id: 24, domain: "Agroalimentaire", country: "Sénégal" },
  { id: 25, domain: "Pétrochimie", country: "Russie" },
  { id: 26, domain: "Mines", country: "Australie" },
  { id: 27, domain: "Agroalimentaire", country: "Côte d'Ivoire" },
  { id: 28, domain: "BTP", country: "Tunisie" },
  { id: 29, domain: "Transport", country: "Maroc" },
  { id: 30, domain: "Technologie", country: "Corée du Sud" },
  { id: 31, domain: "Pharmaceutique", country: "Japon" },
  { id: 32, domain: "Finance", country: "Luxembourg" },
  { id: 33, domain: "Logistique", country: "Égypte" },
];
