import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../redux/slices/authSlices/LogoutSlice";

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated, user } = useSelector((state) => state.auth);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const closeMenu = () => setIsMenuOpen(false);
  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);
  const closeDropdown = () => setIsDropdownOpen(false);

  const handleLogout = () => {
    dispatch(logoutUser()).then(() => {
      navigate("/");
      closeMenu();
    });
  };

  const handleCreateAdClick = (e) => {
    e.preventDefault();
    isAuthenticated ? navigate("/ads/create") : navigate("/login");
  };

  return (
    <nav
      className="navbar navbar-expand-lg navbar-light bg-white shadow-sm fixed-top"
      style={{
        "--bs-navbar-padding-y": "0.6rem",
        fontFamily: "Nunito", fontWeight: "500", fontStyle: "normal"
      }}
    >
      <style>
        {`
         .custom-margin {
            margin: -7px 10px !important;
          }
          .notification-icon {
            position: relative;
            cursor: pointer;
          }
          .notification-badge {
            position: absolute;
            top: -3px;
            right: -3px;
            background: red;
            color: white;
            font-size: 15px;
            font-weight: bold;
            border-radius: 50%;
            width: 18px;
            height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        `}
      </style>

      <div className="container">
        <Link
          className="navbar-brand"
          to="/"
          onClick={closeMenu}
          style={{
            fontSize: "1.4rem", 
            fontWeight: "900", 
            // letterSpacing: "1px", 
          }}
        >
          <span style={{ color: "#003366" }}>ESPACE </span>
          <span style={{ color: "#00cc44" }}>NEGOCE</span>
        </Link>


        <button
          className="navbar-toggler"
          type="button"
          aria-expanded={isMenuOpen ? "true" : "false"}
          onClick={toggleMenu}
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className={`collapse navbar-collapse ${isMenuOpen ? "show" : ""}`} id="navbarNav">
          <div className="ms-auto d-flex flex-column flex-lg-row align-items-start">
            {isAuthenticated && user ? (
              
              <div className="d-flex align-items-center">
              {/* ✅ Dropdown utilisateur */}
              <div
                className="dropdown custom-margin"
                onMouseEnter={toggleDropdown}
                onMouseLeave={closeDropdown}
                style={{ position: "relative" }}
              >
                <button
                  className="btn btn-link"
                  style={{
                    textDecoration: "none",
                    color: "#003366",
                    fontWeight: "bold",
                  }}
                  type="button"
                  id="dropdownMenuButton"
                  aria-expanded={isDropdownOpen}
                >
                  <img
                    src={`https://api.dicebear.com/7.x/pixel-art/svg?seed=User${user.id}`}
                    alt="Profil"
                    className="rounded-circle me-2"
                    style={{ width: "40px", height: "40px" }}
                  />
                  {user.firstname} {user.lastname}
            
                  <i className={`bi ${isDropdownOpen ? "bi-chevron-up" : "bi-chevron-down"} ms-2`}></i>
                </button>
            
                {isDropdownOpen && (
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                    style={{
                      display: "block",
                      position: "absolute",
                      top: "100%",
                      left: "30px",
                      zIndex: 1000,
                      backgroundColor: "#fff",
                      border: "1px solid rgba(0,0,0,.15)",
                      borderRadius: "5px",
                      minWidth: "220px",
                      padding: "15px 0",
                      boxShadow: "0 0.5rem 1rem rgba(0,0,0,.175)",
                      transition: "background-color 0.3s ease",

                    }}
                  >
                    <li className="py-1">
                      <Link className="dropdown-item" to="/dashboard">
                        <i className="bi-person-circle me-2"></i> Espace client
                      </Link>
                    </li>
                    <li className="py-1">
                      <Link className="dropdown-item " to="/messenger">
                        <i className="bi bi-chat-dots me-2"></i> Messagerie
                      </Link>
                    </li>
                    <li className="py-1">
                      <button className="dropdown-item" onClick={handleLogout}>
                        <i className="bi bi-box-arrow-right me-2"></i> Se déconnecter
                      </button>
                    </li>
                  </ul>
                )}
              </div>
            
              <div className="notification-icon ms-2">
                <i className="bi bi-bell fs-3 text-primary"></i>
                <span className="notification-badge">5</span> 
              </div>
            </div>
            
            ) : (
              <>
                <Link
                  to="/login"
                  className="btn btn-link mb-3 mb-lg-0 me-lg-3"
                  style={{
                    textDecoration: "none",
                    color: "#003366",
                    fontWeight: "bold",
                  }}
                  onClick={closeMenu}
                >
                  <i className="bi bi-box-arrow-in-right"></i> Se connecter
                </Link>
                <Link
                  to="/register"
                  className="btn btn-link mb-3 mb-lg-0 me-lg-3"
                  style={{
                    textDecoration: "none",
                    color: "#003366",
                    fontWeight: "bold",
                  }}
                  onClick={closeMenu}
                >
                  <i className="bi bi-person-plus"></i> S'inscrire
                </Link>
              </>
            )}

           

            <Link to="/about" className="btn btn-link me-3 ms-2" style={{ textDecoration: "none", color: "#003366", fontWeight: "bold" }} onClick={closeMenu}>
                À propos de nous
            </Link>

            <Link to="/contact" className="btn btn-link me-3 " style={{ textDecoration: "none", color: "#003366", fontWeight: "bold" }} onClick={closeMenu}>
              Contact
            </Link>



            <button className="btn btn-success me-3 mb-3 mb-lg-0 me-lg-3 mt-3 mt-lg-0"
              style={{ backgroundColor: "#00cc44", borderRadius: "20px", padding: "10px 20px", fontWeight: "500", border: "none" }}
              onClick={handleCreateAdClick}>
              <i className="bi bi-megaphone"></i> Déposer une annonce
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
