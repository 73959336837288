import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "../../../utils/api";

// 🔥 Récupérer la liste des contacts disponibles
export const fetchContacts = createAsyncThunk(
  "userContacts/fetchContacts",
  async (_, { rejectWithValue }) => {
    try {
      const userToken = sessionStorage.getItem("token");
      if (!userToken) throw new Error("Utilisateur non authentifié");

      const response = await axios.get(`${API_BASE_URL}/contacts`, {
        headers: { Authorization: `Bearer ${userToken}` },
      });

      return response.data.map((contact) => ({
        ...contact,
        conversation_id: contact.conversation_id,
      }));
    } catch (error) {
      return rejectWithValue(error.response?.data || "Erreur inconnue");
    }
  }
);

const userContactsSlice = createSlice({
  name: "userContacts",
  initialState: {
    contacts: [],
    status: "idle",
    error: null,
  },
  reducers: {
    resetContactsState: (state) => {
      state.contacts = [];
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContacts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchContacts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.contacts = action.payload;
      })
      .addCase(fetchContacts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetContactsState } = userContactsSlice.actions;
export default userContactsSlice.reducer;
