import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchMessages, sendMessage } from "../redux/slices/chatSlices/userMessage";
import { useParams } from "react-router-dom";

const ChatComponent = () => {
  const { conversationId } = useParams();
  const dispatch = useDispatch();
  const { messages, status, error } = useSelector((state) => state.userMessage);
  const userToken = sessionStorage.getItem("token") || null;

  const [newMessage, setNewMessage] = useState(""); // État pour stocker le message à envoyer

  useEffect(() => {
    console.log("🛠 conversationId récupéré :", conversationId);
    console.log("🛠 userToken récupéré :", userToken);

    if (!conversationId || !userToken) {
      console.warn("⚠️ conversationId ou userToken manquant !");
      return;
    }

    console.log("🔄 Dispatch de fetchMessages...");
    dispatch(fetchMessages(conversationId));

    const interval = setInterval(() => {
      dispatch(fetchMessages(conversationId));
    }, 60000);

    return () => clearInterval(interval);
  }, [conversationId, dispatch, userToken]);

  // ✅ Fonction pour envoyer un message
  const handleSendMessage = () => {
    if (!newMessage.trim()) return;

    dispatch(sendMessage({ conversationId, message: newMessage }))
      .then(() => {
        setNewMessage(""); // Réinitialiser l’input après l’envoi
        dispatch(fetchMessages(conversationId)); // Rafraîchir la liste des messages
      });
  };

  return (
    <div className="container mt-4" style={{ maxWidth: "100%", fontFamily: "Nunito", fontWeight: "500", marginLeft: "210px" }}>
      <h2>Chat</h2>

      {status === "loading" && <p>📩 Chargement des messages...</p>}
      {error && <p>❌ Erreur : {error}</p>}

      <pre>📡 Messages Redux: {JSON.stringify(messages, null, 2)}</pre>

      {messages.length === 0 ? (
        <p>📭 Aucun message pour l'instant...</p>
      ) : (
        messages.map((msg, index) => <p key={index}>{msg.message}</p>)
      )}

      {/* ✅ Zone d'envoi de message */}
      <div className="mt-3">
        <input
          type="text"
          className="form-control"
          placeholder="Écrire un message..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
        />
        <button className="btn btn-primary mt-2 w-100" onClick={handleSendMessage}>
          ➤ Envoyer
        </button>
      </div>
    </div>
  );
};

export default ChatComponent;
