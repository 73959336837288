import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchContacts } from "../../redux/slices/chatSlices/userContacts";
import { fetchMessages, sendMessage } from "../../redux/slices/chatSlices/userMessage";
import { FaPaperPlane } from "react-icons/fa";
import { Link } from "react-router-dom";
import { formatDistanceToNow, format } from "date-fns";
import { fr } from "date-fns/locale";

import "../../styles/chat.css";

const Chat = () => {
  const dispatch = useDispatch();
  const { contacts, status: contactsStatus, error: contactsError } = useSelector((state) => state.userContacts);
  const { messages, status: messagesStatus, error: messagesError } = useSelector((state) => state.userMessage);

  const [selectedContact, setSelectedContact] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [searchTerm, setSearchTerm] = useState(""); // ✅ État pour la recherche
  const chatBodyRef = useRef(null);
  const lastMessageRef = useRef(null);

  useEffect(() => {
    dispatch(fetchContacts());
  }, [dispatch]);

  useEffect(() => {
    if (selectedContact) {
      if (!selectedContact.conversation_id) return;
      dispatch(fetchMessages(selectedContact.conversation_id));

      const interval = setInterval(() => {
        dispatch(fetchMessages(selectedContact.conversation_id));
      }, 60000);

      return () => clearInterval(interval);
    }
  }, [selectedContact, dispatch]);

  useEffect(() => {
    if (contacts.length > 0) {
      setSelectedContact(contacts[0]);
    }
  }, [contacts]);

  const handleSendMessage = () => {
    if (newMessage.trim() === "" || !selectedContact) return;
    if (!selectedContact.conversation_id) return;

    dispatch(sendMessage({ conversationId: selectedContact.conversation_id, message: newMessage }))
      .then(() => {
        setNewMessage("");
        dispatch(fetchMessages(selectedContact.conversation_id));
      });
  };

  // ✅ Filtrer les contacts en fonction du champ de recherche
  const filteredContacts = contacts.filter((contact) =>
    contact.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="container mt-4" 
      style={{ maxWidth: "100%", fontFamily: "Nunito", fontWeight: "500", marginLeft: "210px" }}
    >
      <div className="row">
        {/* ✅ Liste des contacts */}
        <div className="col-md-5 col-lg-4 mb-3">
          <div className="d-flex justify-content-between align-items-center mt-3">            
            <Link to="/dashboard" className="text-decoration-none fw-bold" style={{ color: "#003366", fontSize: "18px" }}>
              <i className="bi bi-arrow-left me-2"></i> Retour à l'espace client
            </Link>
            <button 
              className="btn btn-outline-primary btn-sm"
              style={{ backgroundColor: "#003366", color: "#fff", borderColor: "#003366" }}
              onClick={() => window.location.reload()}
              title="Actualiser la conversation"
            >
              <i className="bi bi-arrow-clockwise"></i>
            </button>
          </div>

          <div className="card shadow-sm border rounded" style={{ padding: "0px", borderRadius: "10px", margin: "12px auto" }}>
            <div className="contacts-container">
              <h4 className="contacts-title">Messagerie</h4>

              {/* ✅ Barre de recherche */}
              <input
                type="text"
                className="form-control mb-2"
                placeholder="Rechercher un contact..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />

              {contactsStatus === "loading" && <p>📩 Chargement des contacts...</p>}
              {contactsError && <p>❌ Erreur : {contactsError}</p>}

              {filteredContacts.length === 0 ? (
                <p className="text-center">📭 Aucun contact trouvé.</p>
              ) : (
                filteredContacts.map((contact) => (
                  <div
                    key={contact.id}
                    className={`contact-item ${selectedContact?.id === contact.id ? "selected" : ""}`}
                    onClick={() => setSelectedContact(contact)}
                  >
                    <img
                      src={`https://api.dicebear.com/7.x/pixel-art/svg?seed=User${contact.id}`}
                      alt="Avatar"
                      className="contact-avatar"
                    />
                    <div className="contact-info">
                      <strong className="contact-name">{contact.name}</strong>
                      <p className="contact-last-message" style={{ color: "#949494" }}>
                        {contact.lastMessage
                          ? contact.lastMessage.length > 30
                            ? `${contact.lastMessage.substring(0, 30)}...`
                            : contact.lastMessage
                          : "Aucun message"}
                      </p>                    
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>

        {/* ✅ Zone de chat */}
        <div className="col-md-7 col-lg-8">
          {selectedContact && (
            <div className="card shadow-sm border rounded" style={{ maxWidth: "800px", padding: "0px", borderRadius: "10px", margin: "25px auto" }}>
              <div className="card-header d-flex align-items-center">
                <img
                  src={`https://api.dicebear.com/7.x/pixel-art/svg?seed=User${selectedContact.id}`}
                  alt="Avatar"
                  className="rounded-circle me-3"
                  style={{ width: "40px", height: "40px" }}
                />
                <strong>{selectedContact.name}</strong>
              </div>

              <div className="card-body chat-body p-3" ref={chatBodyRef} style={{ height: "450px", overflowY: "auto", background: "#f8f9fa" }}>
                {messagesStatus === "loading" && <p>📩 Chargement des messages...</p>}
                {messagesError && <p>❌ Erreur : {typeof messagesError === "string" ? messagesError : JSON.stringify(messagesError)}</p>}
                
                {messages.length === 0 ? (
                  <p>📭 Aucun message pour l'instant...</p>
                ) : (
                  messages.map((msg, index) => (
                    <div 
                      key={msg.id} 
                      className={`d-flex mb-3 ${msg.sender_id === selectedContact.id ? "justify-content-start" : "justify-content-end"}`}
                      ref={index === messages.length - 1 ? lastMessageRef : null}
                    >
                      <div className={`p-2 rounded ${msg.sender_id === selectedContact.id ? "bg-light" : "bg-chat text-white"}`} style={{ maxWidth: "60%" }}>
                        <p className="mb-1">{msg.message}</p>
                        <small className={`d-block ${msg.sender_id === selectedContact.id ? "text-muted" : "text-white"}`} style={{ fontSize: "12px", cursor: "pointer" }}
                          onClick={(e) => e.target.innerText = format(new Date(msg.created_at), "dd MMM yyyy à HH:mm", { locale: fr })}
                        >
                          {formatDistanceToNow(new Date(msg.created_at), { addSuffix: true, locale: fr })}
                        </small>
                      </div>
                    </div>
                  ))
                )}
              </div>

              <div className="card-footer d-flex align-items-center p-2">
                <input
                  type="text"
                  className="form-control me-2 chat-input"
                  placeholder="Votre message ici..."
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
                />
                <button className="chat-button" onClick={handleSendMessage}>
                  <FaPaperPlane size={18} />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Chat;
