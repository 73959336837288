import React, { useEffect, useState } from "react";
import { FaPaperPlane } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchUsers } from "../../redux/slices/userSlices/UserList";
import { fetchConversations } from "../../redux/slices/chatSlices/conversationSlice";
import { startConversation } from "../../redux/slices/chatSlices/conversationSlice";

const Networking = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { users, status, error } = useSelector((state) => state.userList);
  const { conversations } = useSelector((state) => state.conversation); // Récupère toutes les conversations
  const { user } = useSelector((state) => state.userProfile); // Récupère l'utilisateur connecté

  // ✅ Récupérer l'ID de l'utilisateur connecté (Redux ou sessionStorage)
  const userId = user?.id || Number(sessionStorage.getItem("user_id"));

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    dispatch(fetchUsers()); // Charger la liste des utilisateurs
    dispatch(fetchConversations()); // Charger les conversations existantes
  }, [dispatch]);

  // ✅ Exclure l'utilisateur connecté
  const filteredUsers = users.filter(member => member.id !== Number(userId));

  // ✅ Fonction pour gérer la redirection vers une conversation
  const handleStartConversation = (receiverId) => {
    // 🔥 Vérifier si une conversation existe déjà
    const existingConversation = conversations.find(
      convo => 
        (convo.sender_id === userId && convo.receiver_id === receiverId) || 
        (convo.sender_id === receiverId && convo.receiver_id === userId)
    );

    if (existingConversation) {
      // ✅ Si une conversation existe, on redirige directement
      navigate(`/messenger?conversationId=${existingConversation.id}`);
    } else {
      // ✅ Sinon, créer une nouvelle conversation et rediriger
      dispatch(startConversation(receiverId))
        .unwrap()
        .then((newConversation) => {
          navigate(`/messenger?conversationId=${newConversation.id}`);
        })
        .catch((err) => {
          console.error("❌ Erreur lors de la création de la conversation :", err);
        });
    }
  };

  return (
    <div className="container mt-4"
      style={{
        maxWidth: "100%",
        marginLeft: isMobile ? "0px" : "210px",
        fontFamily: "Nunito", fontWeight: "500", fontStyle: "normal"
      }}
    >
      <h4 className="mb-2 mt-4">
        Bonne nouvelle <span role="img" aria-label="smiley">😊</span>, ces membres sont dans le même secteur d'activité que vous !
      </h4>

      {status === "loading" && <p>📩 Chargement des utilisateurs...</p>}
      {error && <p className="text-danger">❌ Erreur : {error}</p>}

      <div className="row justify-content-start">
        {filteredUsers.length > 0 ? (
          filteredUsers.map((member) => (
            <div key={member.id} className="col-12 col-sm-6 col-md-4 col-lg-2 mb-1">
              <div className="card p-3 text-center shadow-sm border rounded" style={{ margin: "10px auto" }}>
                <img
                  src={`https://api.dicebear.com/7.x/pixel-art/svg?seed=User${member.id}`}
                  alt="Avatar"
                  className="mx-auto d-block rounded-circle"
                  style={{ width: "70px", height: "70px" }}
                />

                <p className="mt-3 mb-1 fw-bold" style={{ fontSize: "12px" }}>
                  <span className="text-muted">Domaine d'activité <br /></span>
                  <strong>{member.profile?.domain || "Non renseigné"}</strong>
                </p>

                {/* ✅ Afficher le Nom & Prénom */}
                <p className="fw-bold text-muted" style={{ fontSize: "12px" }}>
                  {member.firstname} {member.lastname}
                </p>

                {/* ✅ Bouton "Message" qui ouvre la conversation */}
                <button
                  className="btn btn-primary w-100 btn-sm"
                  onClick={() => handleStartConversation(member.id)}
                >
                  <FaPaperPlane className="me-2" /> Message
                </button>
              </div>
            </div>
          ))
        ) : (
          <p className="text-center">📭 Aucun membre disponible.</p>
        )}
      </div>
    </div>
  );
};

export default Networking;
