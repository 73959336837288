import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { loginUser } from "../../redux/slices/authSlices/LoginSlice";
import { clearError } from "../../redux/slices/authSlices/authSlice";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, error } = useSelector((state) => state.auth);

  // ✅ States pour le formulaire
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  // ✅ Fonction pour effacer le message d'erreur Redux
  const clearErrorMessage = () => {
    dispatch(clearError());
  };

  // ✅ Gestion de la soumission du formulaire
  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = { email, password };

    dispatch(loginUser(formData)).then((result) => {
      if (result.meta.requestStatus === "fulfilled") {
        const userId = result.payload.user.id; // 🔥 Récupérer l'ID utilisateur connecté
        
        sessionStorage.setItem("user_id", userId); // ✅ Stocker l'ID utilisateur
        
        console.log("✅ ID utilisateur stocké :", userId); // 🔍 Vérification console

        navigate("/dashboard"); // ✅ Rediriger après connexion
      }
    });
  };

  return (
    <div className="container mt-5">
      <div className="col-lg-6 offset-lg-3">
        <div
          className="card p-4"
          style={{
            maxWidth: "400px",
          }}
        >
          <h2>Connexion</h2>

          {/* ✅ Message d'erreur */}
          {error && (
            <div
              className="alert alert-danger"
              role="alert"
              onClick={clearErrorMessage}
              style={{
                fontSize: "14px",
                cursor: "pointer",
              }}
            >
              {error.message || "Une erreur est survenue. Veuillez réessayer."}
            </div>
          )}

          <form onSubmit={handleSubmit}>
            {/* ✅ Email */}
            <div className="mb-3">
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            {/* ✅ Mot de passe */}
            <div className="mb-3 position-relative">
              <input
                type={showPassword ? "text" : "password"}
                className="form-control"
                placeholder="Mot de passe"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <span
                className="password-toggle"
                onClick={() => setShowPassword(!showPassword)}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>

            {/* ✅ Bouton de soumission */}
            <button
              type="submit"
              className="btn btn-primary w-100"
              disabled={isLoading}
            >
              {isLoading ? "Connexion..." : "Se connecter"}
            </button>
          </form>

          {/* ✅ Lien pour mot de passe oublié */}
          <div className="mt-3 text-center">
            <Link
              to="/forgot-password"
              style={{ textDecoration: "none", color: "#003366" }}
            >
              Mot de passe oublié ?
            </Link>
          </div>

          {/* ✅ Lien pour inscription */}
          <div className="mt-3 text-center">
            <Link
              to="/register"
              style={{ textDecoration: "none", color: "#003366" }}
            >
              Vous n'avez pas de compte ? Créez-en un ici
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
