import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "../../../utils/api"; // Assure-toi que ce fichier existe et contient ton URL d'API

// 🔥 Récupérer la liste des utilisateurs avec pagination
export const fetchUsers = createAsyncThunk(
  "userList/fetchUsers",
  async (page = 1, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/users?page=${page}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Erreur inconnue");
    }
  }
);

const userListSlice = createSlice({
  name: "userList",
  initialState: {
    users: [],
    pagination: null,
    status: "idle", // "idle" | "loading" | "succeeded" | "failed"
    error: null,
  },
  reducers: {
    resetUserListState: (state) => {
      state.users = [];
      state.pagination = null;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.users = action.payload.users;
        state.pagination = action.payload.pagination;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetUserListState } = userListSlice.actions;
export default userListSlice.reducer;
